import React from "react"
import { TextField } from "@mui/material"

const Input = (props) => {
  const {
    name,
    label,
    value,
    placeholder,
    fullWidth,
    type,
    onChange,
    style,
    required,
    inputProps,
    textArea,
  } = props
  if (textArea) {
    return (
      <TextField
        variant="outlined"
        required={required}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth={fullWidth}
        type={type}
        inputProps={inputProps}
        multiline
        //rows={2}
        sx={{
          ...style,
        }}
      />
    )
  } else {
    return (
      <TextField
        variant="outlined"
        required={required}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth={fullWidth}
        type={type}
        inputProps={inputProps}
        sx={{
          "& .MuiOutlinedInput-root": {
            height: "40px",
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px 14px",
          },
          ...style,
        }}
      />
    )
  }
}

export default Input
